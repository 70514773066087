import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import ArrowButton from '../components/shared/arrow-button/ArrowButton';
import Layout from '../components/layout/layout';
import { graphql } from 'gatsby';
import { StrapiImage } from '../components/shared/interfaces';
import ImageSliderWithThumbnail from '../components/shared/slider-with-thumbnail/image/ImageSliderWithThumbnail';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Carousel from 'nuka-carousel';
import styles from './showroom.module.scss';
import useViewport from './../hooks/useViewport';
import arrowLeft from '../images/black-arrow-left.svg';
import arrowRight from '../images/black-arrow-right.svg';

export const query = graphql`
  query {
    strapi {
      showroom {
        ourShowroomHead
        galleryHead
        ourProductsHead
        ourShowroomHead_2
        id
        ourShowroomText
        ourProductsGallary {
          url
          caption
        }
        headVideo {
          url
        }
        gallery {
          url
        }
      }
    }
  }
`;

interface Props {
  data: {
    strapi: {
      showroom: {
        ourShowroomHead: string;
        ourShowroomHead_2: string;
        ourShowroomText: string;
        ourProductsHead: string;
        galleryHead: string;
        collageImage: StrapiImage;
        ourProductsGallary: Array<{
          url: string;
          caption?: string;
        }>;
        gallery: Array<StrapiImage>;
        headVideo: StrapiImage | string;
        images: StrapiImage;
      };
    };
  };
}

const Showroom: React.FC<Props> = ({ data: { strapi: data } }: Props) => {
  // const [finishId, setFinishId] = useState(0);

  // const { isTablet } = useViewport();

  // const popupWindow = () => {
  //   const [canRender, setCanRender] = useState(false);

  //   useEffect(() => setCanRender(true));
  //   document.getElementById('carousel-popup');
  // };

  // const openPopup = (event: any) => {
  //   const popupWindow =
  //     typeof document !== `undefined` ? document.getElementById('carousel-popup') : null;
  //   const zoomedImage =
  //     typeof document !== `undefined` ? document.getElementById('zoomed-image') : null;
  //   const imageSrc = event.target.getAttribute('src');
  //   popupWindow.classList.add('shown');
  //   zoomedImage.setAttribute('src', imageSrc);
  // };

  // const closePopup = () => {
  //   const popupWindow =
  //     typeof document !== `undefined` ? document.getElementById('carousel-popup') : null;
  //   popupWindow.classList.remove('shown');
  // };

  return (
    <Layout lightNavbar={false}>
      <div
        style={{
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '28px',
          textAlign: 'center',
        }}
      >
        This page is temporary unavailable
      </div>
      {/* <video autoPlay muted loop width="100%" src={data?.showroom?.headVideo?.url}></video>
      <div className={styles.headingWrap}>
        <h1>{data.showroom.ourShowroomHead}</h1>
        <div className={styles.divideLine}></div>
        <h2>{data.showroom.ourShowroomHead_2}</h2>
        <p className={styles.showroomDescription}>{data.showroom?.ourShowroomText}</p>
      </div>

      <section className="carousel-wrap">
        <h2 className={styles.bottomGalleryHeading}>{data.showroom?.galleryHead}</h2>
        <Carousel
          wrapAround
          slideWidth={'240px'} // pixels required because of Carousel lib
          height={'240px'}
          slidesToShow={isTablet ? 2 : 4}
          slideIndex={0}
          className={styles.sliderBottom}
          renderBottomCenterControls={() => <></>}
          renderCenterLeftControls={({ previousSlide }) => (
            <div className={styles.switchBtn} onClick={previousSlide}>
              <img src={arrowLeft} alt="arrow left" className={styles.slideImgLeft} />
            </div>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <div className={styles.switchBtn} onClick={nextSlide}>
              <img src={arrowRight} alt="arrow right" />
            </div>
          )}
        >
          {data.showroom.gallery.map((s, i) => (
            <div className={styles.sliderInside} key={i}>
              <img
                src={s.url}
                className={styles.slideImg}
                alt="image"
                // data-index={i}
                onClick={openPopup}
              />
            </div>
          ))}
        </Carousel>
        <div className={styles.carouselPopup} id="carousel-popup">
          <div>
            <div className={styles.popupCloseBtn} onClick={closePopup}>
              <img
                id="close-button"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMi4xMjEiIGhlaWdodD0iMjEuNjIxIiB2aWV3Qm94PSIwIDAgMjIuMTIxIDIxLjYyMSI+CiAgPGcgaWQ9IkRlc2t0b3BfQnV0dG9uX0Nsb3NlX0JsYWNrIiBkYXRhLW5hbWU9IkRlc2t0b3AvQnV0dG9uL0Nsb3NlL0JsYWNrIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxLjA2MSAwLjU2MSkiPgogICAgPGcgaWQ9Ikdyb3VwXzYiIGRhdGEtbmFtZT0iR3JvdXAgNiI+CiAgICAgIDxwYXRoIGlkPSJMaW5lIiBkPSJNLjUuNSwyMCwyMCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS13aWR0aD0iMS41Ii8+CiAgICAgIDxwYXRoIGlkPSJMaW5lLTIiIGRhdGEtbmFtZT0iTGluZSIgZD0iTS0uNS41LTIwLDIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjEuNSIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg=="
                alt=""
              />
            </div>

            <img src="" alt="" className={styles.popupZoomedImg} id="zoomed-image" />
          </div>
        </div>
        <div className={styles.divider}></div>
        <section className={styles.topSliderSection}>
          <h2 className={styles.topGalleryHeading}>{data.showroom.ourProductsHead}</h2>
          <ImageSliderWithThumbnail
            slideHeight={'600px !important'}
            images={data.showroom.ourProductsGallary.map((s) => ({
              image: { url: s.url, caption: s.caption },
              caption: s.caption,
            }))}
            setFinishId={setFinishId}
          />
        </section>
        <Link to={'/schedule-visit'} className={styles.button1}>
          <div style={{ marginTop: '6rem', height: '9rem' }} className={styles.scheduleButton}>
            <ArrowButton name={'SCHEDULE YOUR VISIT'} imgLeftMargin={'auto'} />
          </div>
        </Link>
      </section> */}
    </Layout>
  );
};

export default Showroom;
